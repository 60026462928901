export default [
  {
    path: '/dashboard',
    name: 'main-dashboard',
    component: () => import('@/views/main/dashboard/DashboardPage.vue'),
    meta: {
      resource: 'dashboard',
      action: 'view',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },

  {
      path: '/events',
      name: 'main-events',
      component: () => import('@/views/main/event/EventPage.vue'),
      meta: {
        resource: 'events',
        action: 'view',
        pageTitle: 'List Upcoming Events',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Upcoming',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events-finish',
      name: 'main-events-finish',
      component: () => import('@/views/main/event/EventFinishPage.vue'),
      meta: {
        resource: 'events',
        action: 'view',
        pageTitle: 'List Finish Events',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Finish',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/add',
      name: 'main-events-add',
      component: () => import('@/views/main/event/EventAddPage.vue'),
      meta: {
        resource: 'events',
        action: 'add',
        pageTitle: 'Add Event',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/participants',
      name: 'main-events-participants',
      component: () => import('@/views/main/event/EventParticipantPage.vue'),
      meta: {
        resource: 'event_participants',
        action: 'view',
        pageTitle: 'Event Participants',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Participants',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/managers',
      name: 'main-events-managers',
      component: () => import('@/views/main/event/EventManagerPage.vue'),
      meta: {
        resource: 'event_managers',
        action: 'view',
        pageTitle: 'Client Access',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Client Access',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/booths',
      name: 'main-events-booths',
      component: () => import('@/views/main/event/EventBoothPage.vue'),
      meta: {
        resource: 'event_booths',
        action: 'view',
        pageTitle: 'Event Booths',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Booths',
            active: true,
          },
        ],
      },
    },

    {
      path: '/event_booths/:id/questions',
      name: 'main-event-booths-questions',
      component: () => import('@/views/main/event/EventBoothQuestionPage.vue'),
      meta: {
        resource: 'questions',
        action: 'view',
        pageTitle: 'Feedback Form',
        breadcrumb: [
          {
            text: 'Event Booth',
          },
          {
            text: 'Feedback Form',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/scans',
      name: 'main-events-scans',
      component: () => import('@/views/main/event/EventScanPage.vue'),
      meta: {
        resource: 'event_scans',
        action: 'view',
        pageTitle: 'Absensi',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Absensi',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/stats',
      name: 'main-events-stats',
      component: () => import('@/views/main/event/EventStatsPage.vue'),
      meta: {
        resource: 'event_stats',
        action: 'view',
        pageTitle: 'Reporting',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Reporting',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/questions',
      name: 'main-events-questions',
      component: () => import('@/views/main/event/EventQuestionPage.vue'),
      meta: {
        resource: 'questions',
        action: 'view',
        pageTitle: 'Feedback Form',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Feedback Form',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/fields',
      name: 'main-events-fields',
      component: () => import('@/views/main/event/EventFieldPage.vue'),
      meta: {
        resource: 'fields',
        action: 'view',
        pageTitle: 'Fields',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Fields',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/form-setting',
      name: 'main-events-form-setting',
      component: () => import('@/views/main/event/EventFormSettingPage.vue'),
      meta: {
        resource: 'form_setting',
        action: 'view',
        pageTitle: 'Form Setting',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Form Setting',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/edit',
      name: 'main-events-edit',
      component: () => import('@/views/main/event/EventEditPage.vue'),
      meta: {
        resource: 'events',
        action: 'edit',
        pageTitle: 'Edit Event',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/events/:id/view',
      name: 'main-events-view',
      component: () => import('@/views/main/event/EventViewPage.vue'),
      meta: {
        resource: 'events',
        action: 'view',
        pageTitle: 'View Event',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },

    {
      path: '/transactions',
      name: 'transactions',
      component: () => import('@/views/main/transaction/TransactionPage.vue'),
      meta: {
        resource: 'transactions',
        action: 'view',
        pageTitle: 'Transaksi',
        breadcrumb: [
          {
            text: 'Transaksi',
          },
          {
            text: 'Lihat Semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/transactions/:id/view',
      name: 'main-transactions',
      component: () => import('@/views/main/transaction/TransactionView.vue'),
      meta: {
        resource: 'transactions',
        action: 'view',
        pageTitle: 'Lihat Transaksi',
        breadcrumb: [
          {
            text: 'Transaksi',
          },
          {
            text: 'Lihat',
            active: true,
          },
        ],
      },
    },

    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/main/role/RolePage.vue'),
      meta: {
        resource: 'roles',
        action: 'view',
        pageTitle: 'Hak Akses',
        breadcrumb: [
          {
            text: 'Hak Akses',
          },
          {
            text: 'Lihat Semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/users',
      name: 'main-users',
      component: () => import('@/views/main/user/UserPage.vue'),
      meta: {
        resource: 'users',
        action: 'view',
        pageTitle: 'Pengguna',
        breadcrumb: [
          {
            text: 'Pengguna',
          },
          {
            text: 'Lihat Semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/users/stats',
      name: 'main-users-stats',
      component: () => import('@/views/main/user/UserStatsPage.vue'),
      meta: {
        resource: 'users',
        action: 'view',
        pageTitle: 'User Reports',
        breadcrumb: [
          {
            text: 'User',
          },
          {
            text: 'Stats',
            active: true,
          },
        ],
      },
    },

    {
      path: '/users/add',
      name: 'main-users-add',
      component: () => import('@/views/main/user/UsersAdd.vue'),
      meta: {
        resource: 'users',
        action: 'add',
        pageTitle: 'Tambah Pengguna',
        breadcrumb: [
          {
            text: 'Pengguna',
          },
          {
            text: 'Tambah',
            active: true,
          },
        ],
      },
    },

    {
      path: '/users/:id/edit',
      name: 'main-users-edit',
      component: () => import('@/views/main/user/UsersEdit.vue'),
      meta: {
        resource: 'users',
        action: 'edit',
        pageTitle: 'Edit Pengguna',
        breadcrumb: [
          {
            text: 'Pengguna',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/users/:id/view',
      name: 'main-users-view',
      component: () => import('@/views/main/user/UsersView.vue'),
      meta: {
        resource: 'users',
        action: 'view',
        pageTitle: 'Lihat Pengguna',
        breadcrumb: [
          {
            text: 'Pengguna',
          },
          {
            text: 'Lihat',
            active: true,
          },
        ],
      },
    },

    {
      path: '/master/categories',
      name: 'master-categories',
      component: () => import('@/views/master/category/CategoryPage.vue'),
      meta: {
        resource: 'categories',
        action: 'view',
        pageTitle: 'Kategori',
        breadcrumb: [
          {
            text: 'Kategori',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/master/tags',
      name: 'master-tags',
      component: () => import('@/views/master/tag/TagPage.vue'),
      meta: {
        resource: 'tags',
        action: 'view',
        pageTitle: 'Tag',
        breadcrumb: [
          {
            text: 'Tag',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/vouchers',
      name: 'vouchers',
      component: () => import('@/views/main/voucher/VoucherPage.vue'),
      meta: {
        resource: 'vouchers',
        action: 'view',
        pageTitle: 'Voucher',
        breadcrumb: [
          {
            text: 'Voucher',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    // {
    //   path: '/notifications',
    //   name: 'notifications',
    //   component: () => import('@/views/main/notification/NotificationPage.vue'),
    //   meta: {
    //     resource: 'notifications',
    //     action: 'view',
    //     pageTitle: 'Notifikasi',
    //     breadcrumb: [
    //       {
    //         text: 'Notifikasi',
    //       },
    //       {
    //         text: 'Lihat semua',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/configs',
      name: 'configs',
      component: () => import('@/views/master/config/ConfigPage.vue'),
      meta: {
        resource: 'configs',
        action: 'view',
        pageTitle: 'Konfigurasi',
        breadcrumb: [
          {
            text: 'Konfigurasi',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/slides',
      name: 'slides',
      component: () => import('@/views/main/slide/SlidePage.vue'),
      meta: {
        resource: 'slides',
        action: 'view',
        pageTitle: 'Slide',
        breadcrumb: [
          {
            text: 'Slide',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/books',
      name: 'books',
      component: () => import('@/views/main/book/BookPage.vue'),
      meta: {
        resource: 'books',
        action: 'view',
        pageTitle: 'Book',
        breadcrumb: [
          {
            text: 'Book',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/marketplace',
      name: 'listing',
      component: () => import('@/views/main/marketplace/MarketplacePage.vue'),
      meta: {
        resource: 'listing',
        action: 'view',
        pageTitle: 'Listing',
        breadcrumb: [
          {
            text: 'Listing',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    {
      path: '/report',
      name: 'report',
      component: () => import('@/views/main/report/ReportPage.vue'),
      meta: {
        resource: 'report',
        action: 'view',
        pageTitle: 'Laporan',
        breadcrumb: [
          {
            text: 'Laporan',
          },
          {
            text: 'Lihat semua',
            active: true,
          },
        ],
      },
    },

    {
        path: '/master/event_types',
        name: 'master-event-types',
        component: () => import('@/views/master/event_type/EventTypePage.vue'),
        meta: {
          resource: 'event_types',
          action: 'view',
          pageTitle: 'List Event Formats',
          breadcrumb: [
            {
              text: 'Event Formats',
            },
            {
              text: 'List',
              active: true,
            },
          ],
        },
    },

    {
        path: '/master/companies',
        name: 'master-companies',
        component: () => import('@/views/master/company/CompanyPage.vue'),
        meta: {
          resource: 'companies',
          action: 'view',
          pageTitle: 'List Companies',
          breadcrumb: [
            {
              text: 'Companies',
            },
            {
              text: 'List',
              active: true,
            },
          ],
        },
    },

    {
      path: '/master/jobs',
      name: 'master-jobs',
      component: () => import('@/views/master/job/JobPage.vue'),
      meta: {
        resource: 'jobs',
        action: 'view',
        pageTitle: 'List Job Titles',
        breadcrumb: [
          {
            text: 'Job Titles',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
  },

  {
    path: '/master/job_levels',
    name: 'master-job-levels',
    component: () => import('@/views/master/job_level/JobLevelPage.vue'),
    meta: {
      resource: 'job_levels',
      action: 'view',
      pageTitle: 'List Job Levels',
      breadcrumb: [
        {
          text: 'Job Levels',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/master/industries',
    name: 'master-industries',
    component: () => import('@/views/master/industry/IndustryPage.vue'),
    meta: {
      resource: 'industries',
      action: 'view',
      pageTitle: 'List Company Industries',
      breadcrumb: [
        {
          text: 'Company Industries',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/master/company_sizes',
    name: 'master-company-sizes',
    component: () => import('@/views/master/company_size/CompanySizePage.vue'),
    meta: {
      resource: 'company_sizes',
      action: 'view',
      pageTitle: 'List Company Sizes',
      breadcrumb: [
        {
          text: 'Company Sizes',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/master/email_tester',
    name: 'master-email-tester',
    component: () => import('@/views/master/email_tester/EmailTesterPage.vue'),
    meta: {
      resource: 'email_tester',
      action: 'view',
      pageTitle: 'List Email Tester',
      breadcrumb: [
        {
          text: 'Email Tester',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

    {
      path: '/master/fields',
      name: 'master-fields',
      component: () => import('@/views/master/fields/FieldPage.vue'),
      meta: {
        resource: 'fields',
        action: 'view',
        pageTitle: 'List Fields',
        breadcrumb: [
          {
            text: 'Fields',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/master/fields/add',
      name: 'master-fields',
      component: () => import('@/views/master/fields/FieldAdd.vue'),
      meta: {
        resource: 'fields',
        action: 'add',
        pageTitle: 'Add Fields',
        breadcrumb: [
          {
            text: 'Fields',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/master/fields/:id/edit',
      name: 'master-fields',
      component: () => import('@/views/master/fields/FieldEdit.vue'),
      meta: {
        resource: 'fields',
        action: 'edit',
        pageTitle: 'Edit Fields',
        breadcrumb: [
          {
            text: 'Fields',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/master/questions',
      name: 'master-questions',
      component: () => import('@/views/master/questions/QuestionPage.vue'),
      meta: {
        resource: 'questions',
        action: 'view',
        pageTitle: 'List Feedback Forms',
        breadcrumb: [
          {
            text: 'Feedback Forms',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/master/questions/add',
      name: 'master-questions',
      component: () => import('@/views/master/questions/QuestionAdd.vue'),
      meta: {
        resource: 'questions',
        action: 'add',
        pageTitle: 'Add Feedback Form',
        breadcrumb: [
          {
            text: 'Feedback Forms',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/master/questions/:event_id/:event_booth_id/edit',
      name: 'master-questions',
      component: () => import('@/views/master/questions/QuestionEdit.vue'),
      meta: {
        resource: 'questions',
        action: 'edit',
        pageTitle: 'Edit Feedback Form',
        breadcrumb: [
          {
            text: 'Feedback Forms',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/master/questions/:event_id/:event_booth_id/responses',
      name: 'master-questions-responses',
      component: () => import('@/views/master/questions/QuestionResponsePage.vue'),
      meta: {
        resource: 'question_responses',
        action: 'view',
        pageTitle: 'Feedback Form Responses',
        breadcrumb: [
          {
            text: 'Feedback Forms',
          },
          {
            text: 'Responses',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/events/:id/email',
      name: 'main-events-email',
      component: () => import('@/views/main/event/EventMailPage.vue'),
      meta: {
        resource: 'emails',
        action: 'view',
        pageTitle: 'Event Emails',
        breadcrumb: [
          {
            text: 'Event',
          },
          {
            text: 'Emails',
            active: true,
          },
        ],
      },
    },
  ]
  